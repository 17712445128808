import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { Header, Layout, LinkList, SEO} from '../components';

import PageProps from '../models/PageProps';



const AllCategoryPage = ({ pathContext }: PageProps) => {
  const { categories } = pathContext || {};

  return (
    <Layout>
      <SEO isPost={false} titlePrefix={'Blog | '} />
      <Header title='All Categories' icon='document-edit' subHeading='Engineering & UX Blog' />
      <LinkList>
        {(categories || []).map((category, idx) => (
          <h2 key={idx}><Link to={`/categories/${kebabCase(category)}`}>{category}</Link></h2>
        ))}
      </LinkList>
    </Layout>
  );
};

export default AllCategoryPage;
